import checkBillingAddress from '../secondStep/checkBillingAddress';
import popupModal from './popupModal';
import { updateCheckout } from '../updateCart';
import { checkTerms } from '../secondStep/secondStepValidation';
import finishOrder from '../finishOrder';
import checkPaketomat from '../secondStep/checkPaketomat';
/**
 * Shipping methods & Payment methods handler
 */
export default function () {

    $(document).ready(function () {

        $(document).on('change', 'input#billing_house_number, #billing_buyer_tax_id, #billing_company_person_name, #billing_company_person_last-name, #billing_company_name, #billing_company_address', function(){
            $('body').trigger('update_checkout');
        });

    });

    $(document).on('click', '.extra-pay.js-shipping--method', function () {
        $(this).siblings().removeClass('active');
        $(this).addClass('active');

        let input = $(this).find('input');

        if (!input.prop('checked')) {
            input.click();
        }

        setTimeout(function () {
            updateCheckout();
        }, 500);

    })

    $(document).on('click', '.js-accordion--payment-item', function () {
        $(this).siblings().removeClass('active');
        $(this).addClass('active');

        let input = $(this).find('input');

        if (!input.prop('checked')) {
            input.click();
        }

        if ($('.payment_method_ppcp-gateway').hasClass('active')) {
            $('.checkout-data-modal__submit').addClass('hidden');
        } else {
            $('.checkout-data-modal__submit').removeClass('hidden');
        }
        setTimeout(function () {
            updateCheckout();
        }, 500);

    })

    $(document).ready(function () {

        if ($('.payment_method_ppcp-gateway').hasClass('active')) {
            $('.checkout-data-modal__submit').addClass('hidden');
        }

    });

    $(document).on('click', '#fake-terms', function () {

        $('#payment .first-step__checkbox').click();

    })

    $(document).on('change', '#newsletter', function () {

        $('#kl_newsletter_checkbox').click();

    })

    $('.second-step__submit').on('click', function (ev) {
        ev.preventDefault();
        if ($('input#terms_and_conditions').length > 0) {
            if (!checkTerms()) {
                return;
            }
        }
        let address_data = checkBillingAddress();
        let paketomat_data = checkPaketomat();

        if (address_data && paketomat_data) {
            popupModal(address_data);
        }
    });

    $('.checkout-data-modal__submit').on('click', function (e) {
        let address_data = checkBillingAddress();
        let paketomat_data = checkPaketomat();

        if (address_data && paketomat_data && checkTerms()) {
            if ($('.checkout-data-modal').length > 0) {
                popupModal(address_data);
            } else {
                //finishOrder(address_data);
            }
        }

        // e.stopImmediatePropagation()
        // e.preventDefault()
        // return
    });
}
