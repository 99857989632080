export function unsetPaketomatData() {
    $('#billing_expedico_points').val('');
    $('#billing_expedico_title').val('');
    $('#billing_expedico_value').val('');

    $('.extra-pay__desc-data-wrapper').slideUp(300);
}

export function copyPaketomatFieldValues() {
    if($('#billing_expedico_points').val() !== '') {
        $('.js-paketomat-name').text($('#billing_expedico_title').val());
        $('.js-paketomat-address').text($('#billing_expedico_value').val());
    }
}

export default function () {
    $(document).on('click', '.extra-pay__desc-select', function(){
        if ($('#widget-expedico').hasClass('active')) {
            unsetPaketomatData();
            $('#widget-expedico').removeClass('active');
        } else {
            $('#widget-expedico').addClass('active');
            let widgetExpedico = document.getElementById('widget-expedico');
            widgetExpedico.scrollIntoView({ behavior: 'smooth' });
        }
    });

    $(document).on('click', '.js-shipping--method', function(){
        if(!$(this).hasClass('paketomat')) {
            unsetPaketomatData();
        }
    });

}
